import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialogSlide = ({open, setOpen, message, productId}) => {
  const handleClose = () => {
    setOpen(false);
    const element = document.getElementsByClassName('checkout-container')[0]
    element.innerHTML = ''
  };
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{message}</DialogTitle>
        <DialogContent>
          <div className='checkout-container'></div>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handlePayment}>Purchase</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const LogInDialogSlide = ({open, setOpen}) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Upgrade your plan?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Please log into IPDetective to upgrade your plan.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button href={`/login`}>Login</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const Pricing = ({user}) => {
  const [open, setOpen] = React.useState(false)
  const [loginOpen, setLoginOpen] = React.useState(false)
  const [message, setMessage] = React.useState("This is a message")
  const [productId, setProductId] = React.useState(799116)
  const Paddle = window.Paddle;
  const handlePayment = (e) => {
      Paddle.Checkout.open({
        method: 'inline',
        product: productId,
        allowQuantity: false,
        disableLogout: true,
        email: user.email,
        frameInitialHeight: 416,
        frameTarget: 'checkout-container',
        frameStyle: 'width:100%; min-width:312px; background-color: transparent; border: none;' ,
        // successCallback: (data, err) =>{
        //   console.log("Something with paddle is doing something")
        //     console.log(data);
        //     //ADD YOUR EXTRA LOGIC TO SEND TO BACKEND 
        // }
      });
  };

  const handleSeePricing = () => {
    setOpen(true)
    handlePayment()
  }
  const handleSeeLogin = () => {
    setLoginOpen(true)
  }
  const handleSeePricingBasic = () => {
    if (user.email == "") {
      handleSeeLogin()
      return
    }
    setProductId(799116)
    setMessage("Upgrade to basic plan")
    handleSeePricing()
  }
  const handleSeePricingBasicPlus = () => {
    if (user.email == "") {
      handleSeeLogin()
      return
    }
    setProductId(793977)
    setMessage("Upgrade to basic+ plan")
    handleSeePricing()
  }
  const handleSeePricingPremium = () => {
    if (user.email == "") {
      handleSeeLogin()
      return
    }
    setProductId(799114)
    setMessage("Upgrade to premium plan")
    handleSeePricing()
  }
  const handleSeePricingPremiumPlus = () => {
    if (user.email == "") {
      handleSeeLogin()
      return
    }
    setProductId(799115)
    setMessage("Upgrade to premium+ plan")
    handleSeePricing()
  }
  const handleLogin = () => {
    window.location.replace("/login")
  }
  return (
    <Box style={{
      marginTop: '2em'
    }}>
        <AlertDialogSlide open={open} setOpen={setOpen} message={message} productId={productId}></AlertDialogSlide>
        <LogInDialogSlide open={loginOpen} setOpen={setLoginOpen}></LogInDialogSlide>
        <Typography variant="h4">Free</Typography>
        <Typography variant="h6">Up to 1,000 requests per day.</Typography>
        <Button onClick={handleLogin}>Just login</Button>
        <Typography variant="h4">Basic</Typography>
        <Typography variant="h6">Up to 10,000 requests per day.</Typography>
        <Button onClick={handleSeePricingBasic}>Purchase for $10 / month</Button>
        <Typography variant="h4">Basic+</Typography>
        <Typography variant="h6">Up to 30,000 requests per day.</Typography>
        <Button onClick={handleSeePricingBasicPlus}>Purchase for $25 / month</Button>
        <Typography variant="h4">Premium</Typography>
        <Typography variant="h6">Up to 80,000 requests per day.</Typography>
        <Button onClick={handleSeePricingPremium}>Purchase for $50 / month</Button>
        <Typography variant="h4">Premium+</Typography>
        <Typography variant="h6">Up to 200,000 requests per day.</Typography>
        <Button onClick={handleSeePricingPremiumPlus}>Purchase for $100 / month</Button>
    </Box>
  );
};
export default Pricing;