
import * as React from 'react';
import TopNavBar from './TopNavBar'
import Home from './Home'
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import PricingNew from './PricingNew';
import Pricing from './Pricing';
import API from './API';
import FAQ from './FAQ';
import Contact from './Contact';
import Account from './Account';
import Logout from './Logout';
import Container from '@mui/material/Container';
import Search from './Search';
import Admin from './Admin';
import AdminUsers from './AdminUsers';
import Register from './Register';
import Footer from './Footer';
import TermsConditionsAndPrivacy from './TermsConditionsAndPrivacy';
import AdminAudit from './AdminAudit';
import AdminContactMessage from './AdminContactMessage';
import { Blog, BlogRouter} from './Blog';
import RegisterForm from './RegisterForm';
import ReactGA from 'react-ga';
import SearchEmail from './SearchEmail';
import ChangeLog from './ChangeLog';
import SignIn from './NewLogin';
import SearchAsnNumber from './SearchAsnNumber';
import AppSumoRegister from './AppSumoRegister';

function IPDetective({user, setUser, config}) {
  ReactGA.initialize(config.TRACKING_CODE);
  React.useEffect(() => {
    console.log("virtual page view: " + window.location.pathname)
    ReactGA.pageview(window.location.pathname);
  }, [])
  return (
    <div className="App">
      <TopNavBar user={user}></TopNavBar>
      <Container maxWidth="md" style={{
        marginTop: '2em'
      }}>
        <Router>
            <Routes>
              <Route path="/" element={<Home config={config}/>} />
              <Route path="/pricing" element={<PricingNew user={user} pageConfig={config.PAGE_CONFIG.pricing}/>} />
              <Route path="/pricing-old" element={<Pricing user={user}/>} />
              <Route path="/api" element={<API config={config}></API>} />
              <Route path="/faq" element={<FAQ pageConfig={config.PAGE_CONFIG.faq}></FAQ>} />
              <Route path="/contact" element={<Contact user={user} pageConfig={config.PAGE_CONFIG.contact}></Contact>} />
              <Route path="/login" element={<SignIn user={user} config={config}></SignIn>} />
              <Route path="/account" element={<Account user={user} setUser={setUser}></Account>} />
              <Route path="/logout" element={<Logout setUser={setUser}></Logout>} />
              <Route path="/search" element={<config.PAGE_CONFIG.search.component user={user}></config.PAGE_CONFIG.search.component>} />
              <Route path="/search-email" element={<SearchEmail user={user}></SearchEmail>} />
              <Route path="/admin" element={<Admin user={user}></Admin>} />
              <Route path="/app-sumo" element={<AppSumoRegister user={user} config={config}></AppSumoRegister>} />
              <Route path="/admin/user" element={<AdminUsers user={user}></AdminUsers>} />
              <Route path="/admin/message" element={<AdminContactMessage user={user}></AdminContactMessage>} />
              <Route path="/admin/audit" element={<AdminAudit user={user}></AdminAudit>} />
              <Route path="/register" element={<Register setUser={setUser}></Register>} />
              <Route path="/terms" element={<TermsConditionsAndPrivacy></TermsConditionsAndPrivacy>} />
              <Route path="/blog" element={<Blog pageConfig={config.PAGE_CONFIG.blog}></Blog>} />
              <Route path="/blog/:id" element={<BlogRouter pageConfig={config.PAGE_CONFIG.blog}></BlogRouter>} />
              <Route path="/checkout" element={<RegisterForm></RegisterForm>} />
              <Route path="/changelog" element={<ChangeLog pageConfig={config.PAGE_CONFIG.changelog}></ChangeLog>}/>
              <Route path="/search-asn" element={<SearchAsnNumber user={user}/>} />
            </Routes>
        </Router>
      </Container>
      <Footer></Footer>
    </div>
  )
}
export default IPDetective;
